var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
        [
          _c(
            "b-form-group",
            { attrs: { label: _vm.FormMSG(57, "Transportation mean") } },
            [
              _c("v-select", {
                class: { "is-invalid": _vm.$v.transportationMeanType.$error },
                attrs: {
                  options: _vm.transportOptions,
                  label: "text",
                  reduce: (option) => option.value,
                  placeholder: _vm.FormMSG(17, "Select a type"),
                  clearable: false,
                  selectable: _vm.selectableTypeItem,
                  disabled: _vm.disableForm,
                },
                on: { "option:selected": _vm.handleOptionSelected },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function (option) {
                      return [
                        _c(
                          "div",
                          {
                            class: `${
                              (option.disabled && option.disabled === true) ||
                              (option.selectable && option.selectable === true)
                                ? "text-bold"
                                : "text-color ml-3"
                            }`,
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(option.text) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.$v.transportationMeanType.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.transportationMeanType, "$model", $$v)
                  },
                  expression: "$v.transportationMeanType.$model",
                },
              }),
              _vm.$v.transportationMeanType.$error
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.FormMSG(53, "Please, select a type")) +
                        "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showDetail
        ? _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(58, "Detail") } },
                [
                  _c("v-select", {
                    class: { "is-invalid": _vm.$v.transportationDetail.$error },
                    attrs: {
                      options: _vm.detailsOptions,
                      label: "text",
                      reduce: (option) => option.value,
                      placeholder: _vm.FormMSG(85, "Please select"),
                      clearable: false,
                      disabled:
                        _vm.disableForm || _vm.transportationMeanType === null,
                    },
                    on: { "option:selected": _vm.handleDetailOptionSelected },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selected-option",
                          fn: function ({ value, text }) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(_vm.sliceAWord(text)) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      778645152
                    ),
                    model: {
                      value: _vm.$v.transportationDetail.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.transportationDetail, "$model", $$v)
                      },
                      expression: "$v.transportationDetail.$model",
                    },
                  }),
                  _vm.$v.transportationDetail.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.FormMSG(53, "Please, select a type")) +
                            "\n\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showAdditionalDetail
        ? _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(59, "Additional detail") } },
                [
                  _c("v-select", {
                    class: { "is-invalid": _vm.$v.transportationDetail.$error },
                    attrs: {
                      options: _vm.additionalDetailsOptions,
                      label: "text",
                      reduce: (option) => option.value,
                      placeholder: _vm.FormMSG(85, "Please select"),
                      clearable: false,
                      disabled:
                        _vm.disableForm || _vm.transportationDetail === null,
                    },
                    model: {
                      value: _vm.$v.transportationAdditionalDetail.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.transportationAdditionalDetail,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.transportationAdditionalDetail.$model",
                    },
                  }),
                  _vm.$v.transportationAdditionalDetail.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.FormMSG(53, "Please, select a type")) +
                            "\n\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showHireRentalOption
        ? _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                [
                  _vm.$screen.width > 576
                    ? _c("label", [_vm._v(" ")])
                    : _vm._e(),
                  _c("b-form-radio-group", {
                    attrs: {
                      options: _vm.statusOptions,
                      name: "radio-options",
                      plain: "",
                      disabled: _vm.disableForm,
                    },
                    model: {
                      value: _vm.$v.transportationAdditionalDetail.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.transportationAdditionalDetail,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.transportationAdditionalDetail.$model",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }