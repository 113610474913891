<template>
	<b-row>
		<b-col sm="12" md="4" lg="4" xl="4">
			<b-form-group :label="FormMSG(57, 'Transportation mean')">
				<v-select
					v-model="$v.transportationMeanType.$model"
					:options="transportOptions"
					label="text"
					:reduce="(option) => option.value"
					:placeholder="FormMSG(17, 'Select a type')"
					:clearable="false"
					:selectable="selectableTypeItem"
					:class="{ 'is-invalid': $v.transportationMeanType.$error }"
					:disabled="disableForm"
					@option:selected="handleOptionSelected"
				>
					<template #option="option">
						<div
							:class="`${
								(option.disabled && option.disabled === true) || (option.selectable && option.selectable === true)
									? 'text-bold'
									: 'text-color ml-3'
							}`"
						>
							{{ option.text }}
						</div>
					</template>
				</v-select>
				<div v-if="$v.transportationMeanType.$error" class="invalid-feedback">
					{{ FormMSG(53, 'Please, select a type') }}
				</div>
			</b-form-group>
		</b-col>
		<b-col v-if="showDetail" sm="12" md="4" lg="4" xl="4">
			<b-form-group :label="FormMSG(58, 'Detail')">
				<v-select
					v-model="$v.transportationDetail.$model"
					:options="detailsOptions"
					label="text"
					:reduce="(option) => option.value"
					:placeholder="FormMSG(85, 'Please select')"
					:clearable="false"
					:class="{ 'is-invalid': $v.transportationDetail.$error }"
					:disabled="disableForm || transportationMeanType === null"
					@option:selected="handleDetailOptionSelected"
				>
					<template #selected-option="{ value, text }">
						<div>
							{{ sliceAWord(text) }}
						</div>
					</template>
				</v-select>
				<div v-if="$v.transportationDetail.$error" class="invalid-feedback">
					{{ FormMSG(53, 'Please, select a type') }}
				</div>
			</b-form-group>
		</b-col>
		<b-col v-if="showAdditionalDetail" sm="12" md="4" lg="4" xl="4">
			<b-form-group :label="FormMSG(59, 'Additional detail')">
				<v-select
					v-model="$v.transportationAdditionalDetail.$model"
					:options="additionalDetailsOptions"
					label="text"
					:reduce="(option) => option.value"
					:placeholder="FormMSG(85, 'Please select')"
					:clearable="false"
					:class="{ 'is-invalid': $v.transportationDetail.$error }"
					:disabled="disableForm || transportationDetail === null"
				/>
				<div v-if="$v.transportationAdditionalDetail.$error" class="invalid-feedback">
					{{ FormMSG(53, 'Please, select a type') }}
				</div>
			</b-form-group>
		</b-col>
		<b-col v-if="showHireRentalOption" sm="12" md="4" lg="4" xl="4">
			<b-form-group>
				<label v-if="$screen.width > 576">&nbsp;</label>
				<b-form-radio-group
					v-model="$v.transportationAdditionalDetail.$model"
					:options="statusOptions"
					name="radio-options"
					plain
					:disabled="disableForm"
				/>
			</b-form-group>
		</b-col>
	</b-row>
</template>

<script>
import { noTilde } from '~utils';
import languageMessages from '@/mixins/languageMessages';
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { TRANSPORTATION_MEAN_MENU, TRANSPORTATION_MEAN_DETAIL_MENU } from '@/shared/constants';

export default {
	name: 'TransportTypeSelector',

	mixins: [languageMessages, validationMixin],

	props: {
		hideBoatType: { type: Boolean, default: false, required: false },
		hideBusType: { type: Boolean, default: false, required: false },
		hideCarType: { type: Boolean, default: false, required: false },
		hideOtherType: { type: Boolean, default: false, required: false },
		hideFreightType: { type: Boolean, default: false, required: false },
		dataType: { type: [Number, undefined], required: true },
		dataDetail: { type: [Number, undefined], required: true },
		dataAdditionalDetail: { type: [Number, undefined], required: true },
		disableForm: { type: Boolean, default: false, required: false },
		stillDisplay: { type: Boolean, default: false, required: false },
		hideMenuesNotTwoWheels: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			transportationMeanType: null,
			transportationDetail: null,
			transportationAdditionalDetail: null,

			detailsOptions: [],
			additionalDetailsOptions: []
		};
	},

	watch: {
		dataType: {
			handler(newVal) {
				if (newVal > 0) {
					this.transportationMeanType = newVal;
					this.$nextTick(() => {
						this.handleOptionSelected({ value: newVal }, false);
					});
				} else {
					this.transportationMeanType = null;
				}
			},
			immediate: true,
			deep: true
		},
		dataDetail: {
			handler(newVal) {
				if (newVal > 0) {
					this.transportationDetail = newVal;
					this.$nextTick(() => {
						this.handleDetailOptionSelected({ value: newVal }, false);
					});
				} else {
					this.transportationDetail = null;
				}
			},
			immediate: true,
			deep: true
		},
		dataAdditionalDetail: {
			handler(newVal) {
				this.transportationAdditionalDetail = newVal === 0 ? null : newVal;
			},
			immediate: true,
			deep: true
		},
		transportationMeanType: {
			handler(newVal) {
				this.emitEvent();
			}
		},
		transportationDetail: {
			handler(newVal) {
				this.emitEvent();
			}
		},
		transportationAdditionalDetail: {
			handler(newVal) {
				this.emitEvent();
			}
		}
	},

	validations() {
		return {
			transportationMeanType: { required },
			transportationDetail: { required: this.showDetail ? required : false },
			transportationAdditionalDetail: { required: this.showAdditionalDetail ? required : false }
		};
	},

	computed: {
		showDetail() {
			if (this.stillDisplay && this.transportationMeanType === null) {
				return true;
			}
			const menues = [6, 7, 8, 12, 13, 15, 16];
			return this.transportationMeanType !== null && menues.includes(this.transportationMeanType);
		},
		showAdditionalDetail() {
			if (this.stillDisplay && this.transportationDetail === null && this.transportationMeanType === null) {
				return true;
			}
			const menues = [5, 14, 15];

			return this.transportationMeanType !== null && this.transportationDetail !== null && menues.includes(this.transportationDetail);
		},
		showHireRentalOption() {
			const menues = [1, 2, 3, 4];
			return !_.isNil(this.transportationMeanType) && !_.isNil(this.transportationDetail) && menues.includes(this.transportationDetail);
		},
		statusOptions() {
			return this.FormMenu(TRANSPORTATION_MEAN_DETAIL_MENU.HIRE_RENTAL);
		},
		freightOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.FREIGHT);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 169,
					text: this.FormMSG(69, 'Freight'),
					disabled: true
				},
				...menus.filter((menu) => {
					if (!this.hideMenuesNotTwoWheels) {
						return menu.text !== 'not found';
					} else {
						return menu.text !== 'not found' && ![17, 18, 19].includes(menu.value);
					}
				})
			];
		},
		boatOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.BOAT);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 139,
					text: this.FormMSG(39, 'Boat'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		busOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.BUS);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 140,
					text: this.FormMSG(40, 'Bus'),
					disabled: true
				},
				...menus.filter((menu) => {
					if (!this.hideMenuesNotTwoWheels) {
						return menu.text !== 'not found';
					} else {
						return menu.text !== 'not found' && menu.value !== 5;
					}
				})
			];
		},
		carOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.CAR);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 141,
					text: this.FormMSG(41, 'Car'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		otherOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.OTHER);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 142,
					text: this.FormMSG(42, 'Other'),
					disabled: true
				},
				...menus.filter((menu) => {
					if (!this.hideMenuesNotTwoWheels) {
						return menu.text !== 'not found';
					} else {
						return menu.text !== 'not found' && ![9, 10, 12, 14, 15].includes(menu.value);
					}
				})
			];
		},
		transportOptions() {
			let menus = [];
			let headeroptions = [
				{
					value: 139,
					text: this.FormMSG(39, 'Boat')
				},
				{
					value: 140,
					text: this.FormMSG(40, 'Bus')
				},
				{
					value: 141,
					text: this.FormMSG(41, 'Car')
				},
				{
					value: 169,
					text: this.FormMSG(69, 'Freight')
				}
			];

			headeroptions.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			let principalOptions = [];

			for (let i = 0; i < headeroptions.length; i++) {
				if (headeroptions[i].value === 139 && this.hideBoatType === false) {
					principalOptions.push(...this.boatOptions);
				} else if (headeroptions[i].value === 140 && this.hideBusType === false) {
					principalOptions.push(...this.busOptions);
				} else if (headeroptions[i].value === 141 && this.hideCarType === false) {
					principalOptions.push(...this.carOptions);
				} else if (headeroptions[i].value === 169 && this.hideFreightType === false) {
					principalOptions.push(...this.freightOptions);
				}
			}

			if (this.hideOtherType === false) {
				menus = [...principalOptions, ...this.otherOptions];
			}

			return menus;
		}
	},

	methods: {
		emitEvent() {
			const resultTransportationDetail = this.transportationDetail === null ? 0 : this.transportationDetail;

			let resultTransportationAdditionalDetail = 0;
			if (!_.isNil(this.transportationAdditionalDetail) && !_.isNil(this.transportationAdditionalDetail.value)) {
				resultTransportationAdditionalDetail = this.transportationAdditionalDetail.value === null ? 0 : this.transportationAdditionalDetail.value;
			} else {
				resultTransportationAdditionalDetail = this.transportationAdditionalDetail === null ? 0 : this.transportationAdditionalDetail;
			}

			this.$emit('transportation-type-selector:change', {
				type: this.transportationMeanType,
				detail: resultTransportationDetail,
				additionalDetail: resultTransportationAdditionalDetail
			});
		},
		sliceAWord(value) {
			if (value.length > 20) {
				return value.substring(0, 20) + ' ...';
			}

			return value;
		},
		isInvalid() {
			this.$v.$touch();

			return this.$v.$invalid;
		},
		handleDetailOptionSelected(payload, init = true) {
			if ([1, 2, 3, 4].includes(payload.value)) {
				if (init) {
					this.transportationAdditionalDetail = 7;
				}
			}
			if ([5, 6, 7, 8].includes(payload.value)) {
				if (init) {
					this.transportationAdditionalDetail = null;
				}

				this.getAdditionalsDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.PLANE_TYPE);
			} else if ([14, 15].includes(payload.value)) {
				if (init) {
					this.transportationAdditionalDetail = null;
				}

				this.getAdditionalsDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.CAR);
			}
		},
		getAdditionalsDetailsOptions(menuNumber) {
			let menus = this.FormMenu(menuNumber);

			menus.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			this.additionalDetailsOptions = menus;
		},
		handleOptionSelected(payload, init = true) {
			const carMenues = [6, 7, 8];

			if (carMenues.includes(payload.value)) {
				if (init) {
					this.transportationDetail = null;
					this.transportationAdditionalDetail = null;
				}

				this.getDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.CAR); // MENU FOR CAR
			} else if (payload.value === 12) {
				if (init) {
					this.transportationDetail = null;
					this.transportationAdditionalDetail = null;
				}

				this.getDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.PLANE); // MENU FOR PLANE
			} else if (payload.value === 13) {
				if (init) {
					this.transportationDetail = null;
					this.transportationAdditionalDetail = null;
				}

				this.getDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.SCOOTER); // MENU FOR SCOOTER
			} else if (payload.value === 15) {
				if (init) {
					this.transportationDetail = null;
					this.transportationAdditionalDetail = null;
				}

				this.getDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.TRAIN); // MENU FOR TRAIN
			} else if (payload.value === 16) {
				if (init) {
					this.transportationDetail = null;
					this.transportationAdditionalDetail = null;
				}

				this.getDetailsOptions(TRANSPORTATION_MEAN_DETAIL_MENU.VAN); // MENU FOR VAN
			} else {
				if (init) {
					this.transportationDetail = null;
					this.transportationAdditionalDetail = null;
				}
			}
		},
		getDetailsOptions(menuNumber) {
			let menus = this.FormMenu(menuNumber);

			menus.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			this.detailsOptions = menus;
		},
		selectableTypeItem(item) {
			return _.isNil(item.disabled) || (!_.isNil(item.disabled) && item.disabled === false);
		}
	}
};
</script>
